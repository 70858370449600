import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Home } from 'components/App/FrontOffice/Home/Home';
import { NewsDetails } from 'components/App/FrontOffice/NewsDetails/NewsDetails';
import { NewsPreview } from 'components/App/FrontOffice/NewsPreview/NewsPreview';
import { getFrontOfficePath } from 'components/App/utils';
import { FrontOfficeRoutes } from 'router/routes';

export const FrontOfficeRouter: React.FC = () => (
  <Switch>
    <Route exact path={getFrontOfficePath()}>
      <Home />
    </Route>
    <Route exact path={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS)}>
      <NewsDetails />
    </Route>
    <Route exact path={getFrontOfficePath(FrontOfficeRoutes.NEWS_PREVIEW)}>
      <NewsPreview />
    </Route>
    <Redirect to={getFrontOfficePath()} />
  </Switch>
);
