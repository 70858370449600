import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { BouyguesLinks } from 'components/App/BouyguesLinks/BouyguesLinks';
import { getEligibilityPath, getFrontOfficePath } from 'components/App/utils';
import { ContactDirectory } from 'components/ContactDirectory/ContactDirectory';
import GlobalErrorModal from 'components/GlobalErrorModal/GlobalErrorModal';
import Icon from 'components/Icon/Icon';
import IconSprite from 'components/Icon/IconSprite';
import { useTranslation } from 'components/Localization/Localisation';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import AppBox from 'components/Toolbox/AppBox';
import { Toolkit } from 'components/Toolkit/Toolkit';
import { FrontOfficeHomeTabs } from 'components/UI/FrontOfficeHomeTabs/FrontOfficeHomeTabs';
import { withFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { useIsUserAdmin } from 'hooks/useIsUserAdmin';
import { FrontOfficeRouter } from 'router/AppInternal/FrontOfficeRouter';
import { FrontOfficeRoutes } from 'router/routes';
import { User } from 'types/User';

import './FrontOffice.css';

type Props = ConnectedProps<typeof connector>;

export enum TabToDisplay {
  APPS = 'APPS',
  NEWS = 'NEWS',
}

const FrontOffice: React.FC<Props> = props => {
  const { t } = useTranslation();

  const isOnNewsPreviewPage = useRouteMatch(getFrontOfficePath(FrontOfficeRoutes.NEWS_PREVIEW));

  const [tabToDisplay, setTabToDisplay] = useState(TabToDisplay.NEWS);

  const tabOnClick = (tabValueToSet: TabToDisplay): void => {
    setTabToDisplay(tabValueToSet);
  };

  const isUserLoggedIn = !!props.user && props.user.uuid !== null;
  const isUserAdmin = useIsUserAdmin();
  //@ts-expect-error TODO: explain why ts-expect-error is needed
  const isUserSupport = props.user.roles.includes('ROLE_SUPPORT');

  return (
    <div className="app-home">
      <SiteHeader>
        <ContactDirectory />
        {isUserLoggedIn && !isUserAdmin && isUserSupport ? (
          <NavLink to={getEligibilityPath()} className="active">
            <Icon name="search" width="20px" height="20px" />

            <span>
              {
                //@ts-expect-error TODO: explain why ts-expect-error is needed
                t('eligibility_test')
              }
            </span>
          </NavLink>
        ) : null}
      </SiteHeader>
      <div className="home-container">
        <div className="home-left-container">
          <div className="home-left-content">
            <AppBox />
            <Toolkit />
            <BouyguesLinks />
          </div>
          {isOnNewsPreviewPage && <div className="preview-overlay" />}
        </div>
        <div className="home-right-container">
          {tabToDisplay === TabToDisplay.NEWS && <FrontOfficeRouter />}
          {tabToDisplay === TabToDisplay.APPS && <AppBox />}
        </div>
        <FrontOfficeHomeTabs tabOnClick={tabOnClick} tabToDisplay={tabToDisplay} />
      </div>
      <GlobalErrorModal />
      {/*This component does NOT render any dom, it is only included to load the SVG sprite*/}
      <IconSprite />
    </div>
  );
};

const connector = connect((state: { user: User; notifications: unknown }) => ({
  user: state.user,
}));

export default withFrontOfficeLocalScopeContext(connector(FrontOffice));
